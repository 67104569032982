import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { getDevice } from '~/libs/es/device'

export default function ({ pagination }) {
    const [isPhone, setIsPhone] = useState(false)

    useEffect(() => {
        if (getDevice() === 'phone') {
            setIsPhone(true)
        } else {
            setIsPhone(false)
        }
    }, [])

    const { totalPage = 0, currentPage = 1, path } = pagination
    const pages = new Array(totalPage).fill()

    return <>
        {
            totalPage > 1
            && <nav className="pagination is-centered is-rounded">
                <Link disabled={currentPage === 1} to={`/${path}/${currentPage - 1}`} className="pagination-previous">Previous</Link>
                <Link disabled={currentPage === totalPage} to={`/${path}/${currentPage + 1}`} className="pagination-next">Next</Link>

                {
                    !isPhone
                    && <ul className="pagination-list">
                        {pages.map((each, idx) => {
                            return <li key={idx}>
                                <Link to={`/${path}/${idx + 1}`} className={`pagination-link ${idx === currentPage - 1 && 'is-current'}`}>{idx + 1}</Link>
                            </li>
                        })}
                    </ul>
                }
            </nav>
        }
    </>
}