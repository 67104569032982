import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { getDevice } from '~/libs/es/device'
import { displayDate } from '~/libs/es/formatter'
import { BlackLink, Tag } from 'components/styled'

export default function ({ item }) {
    const [isPhone, setIsPhone] = useState(false)

    useEffect(() => {
        if (getDevice() === 'phone') {
            setIsPhone(true)
        } else {
            setIsPhone(false)
        }
    }, [])

    const { context: { title, to, excerpt, heroImage, articleDate, categories } } = item

    return <>
        <div className="card" style={{ margin: '10px' }}>
            <div className="card-content">
                <div className="media" style={{ marginBottom: '1rem' }}>
                    {
                        !isPhone
                        && <div className="media-left" style={{ marginRight: '1.5em' }}>
                            <figure className="image is-64x64">
                                <img src={`/images/128x128/${heroImage}`} alt={title} />
                            </figure>
                        </div>
                    }

                    <div className="media-content">
                        <Link to={to}>
                            <p className="title is-5" style={{ marginTop: '0.2em', marginBottom: '0.3em' }}>{title}</p>
                        </Link>
                        <p>
                            <Tag className="tag is-warning">{displayDate(articleDate)}</Tag>
                            {
                                categories.map((each, idx) => {
                                    return <Link key={idx} to={`/category/${each}`}><Tag className="tag is-info">#{each}</Tag></Link>
                                })
                            }
                        </p>
                    </div>
                </div>

                <div className="content">
                    <BlackLink to={to}>
                        <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
                    </BlackLink>

                    <p style={{ marginTop: '1em' }}>
                        <Link to={to}>
                            <span className="tag is-rounded" style={{ backgroundColor: '#f5f5f5' }}>อ่านต่อ ...</span>
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    </>
}