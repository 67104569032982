export function displayDate(date, weekDay = false) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' }
    const timestamp = Date.parse(date)

    return new Date(timestamp).toLocaleDateString('th-TH', !weekDay ? options : { ...options, weekday: 'short' })
}

export function displayDateTime(date) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    const d = new Date(date)

    return `${d.toLocaleString('th-TH', options)} ${d.toLocaleTimeString()}`
}

export function displayPrice(price) {
    return new Intl.NumberFormat().format((typeof price !== 'number') ? parseInt(price) : price)
}