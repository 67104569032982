import React from 'react'

import Pagination from 'components/pagination'
import ArticleListItem from 'components/article-list-item'

export default function ({ items = [], pagination }) {
    return <>
        {
            items.map(each => {
                return <ArticleListItem key={each.context.id} item={each} />
            })
        }

        <hr />

        <Pagination pagination={{ ...pagination, path: 'article' }} />
    </>
}