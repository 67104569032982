import React from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import { generateRelateArticles } from '~/libs/es/helper'
import { RELATE_ARTICLE_SET } from '~/store'

import ArticleList from 'components/article-list'

export default connect()(function ({ dispatch, pageContext, data }) {
    const { filterArticle, allArticle } = data

    dispatch({
        type: RELATE_ARTICLE_SET,
        payload: generateRelateArticles(allArticle.articles)
    })

    return <>
        <Helmet>
            <title>{pageContext.helmetTitle}</title>
        </Helmet>

        <ArticleList items={filterArticle.articles} />
    </>
})

export const query = graphql`
    query($category: String) {
        filterArticle: allSitePage(filter: {path: {regex: "/article/\\w+/"}, context: { title: { ne: null }, categories: { eq: $category}}}, sort: {fields: context___articleDate, order: DESC}) {
            articles: nodes {
                context {
                    id
                    title
                    to
                    excerpt
                    heroImage
                    articleDate
                    categories
                }
            }
        }

        allArticle: allSitePage(filter: {path: {regex: "/article/\\w+/"}, context: { title: { ne: null }, categories: { eq: $category}}}, sort: {fields: context___articleDate, order: DESC}) {
            articles: nodes {
                context {
                    id
                    title
                    to
                }
            }
        }
    }
`